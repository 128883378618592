import * as React from 'react'
import axios, { AxiosResponse } from 'axios'

export interface ILocationData {
  state: string
  zip: string
  city: string
  code?: string
}

enum GeolocationCodes {
  ZIP = 'postal_code',
  CITY = 'locality',
  STATE = 'administrative_area_level_1',
}

enum GeoLocationCookieType {
  GEOLOCATION = 'USER_GEOLOCATION',
}

const useGeolocation = () => {
  const [location, setLocation] = React.useState<ILocationData>()
  React.useEffect(() => {
    getGeolocationFromSource()
  }, [])
  const getGeolocationFromSource = async () => {
    if (!location && !localStorage.getItem(GeoLocationCookieType.GEOLOCATION)) {
      let zip = '',
        city = '',
        state = ''
      const coords: AxiosResponse = await axios.post(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      )
      const lat: number = coords.data.location.lat
      const lng: number = coords.data.location.lng

      const location: AxiosResponse = await axios.post(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      )
      const geolocation = location.data
      if (geolocation?.results) {
        const postalComponent = geolocation.results.filter((item: any) => item?.types.includes(GeolocationCodes.ZIP))[0]
        // if zip component is present
        if (postalComponent) {
          const { address_components } = postalComponent
          zip = address_components.filter((item: any) => item.types.includes(GeolocationCodes.ZIP))[0]?.long_name || ''
          city =
            address_components.filter((item: any) => item.types.includes(GeolocationCodes.CITY))[0]?.long_name || ''
          state =
            address_components.filter((item: any) => item.types.includes(GeolocationCodes.STATE))[0]?.short_name || ''
          return setLocationFromSource({ zip, city, state })
        }

        const cityComponent = geolocation.results.filter((item: any) => item?.types.includes(GeolocationCodes.CITY))[0]
        // if city component is present
        if (cityComponent) {
          const { address_components } = cityComponent
          city =
            address_components.filter((item: any) => item.types.includes(GeolocationCodes.CITY))[0]?.long_name || ''
          state =
            address_components.filter((item: any) => item.types.includes(GeolocationCodes.STATE))[0]?.short_name || ''
          return setLocationFromSource({ zip, city, state })
        }

        const stateComponent = geolocation.results.filter((item: any) =>
          item?.types.includes(GeolocationCodes.STATE),
        )[0]
        //if state compoent is present
        if (stateComponent) {
          const { address_components } = stateComponent
          state =
            address_components.filter((item: any) => item.types.includes(GeolocationCodes.STATE))[0]?.short_name || ''
          return setLocationFromSource({ zip, city, state })
        }
      }
      return setLocationFromSource({ zip, city, state })
    }
  }
  const setLocationFromSource = (location: ILocationData) => {
    setLocation(location)
    localStorage.setItem(GeoLocationCookieType.GEOLOCATION, JSON.stringify(location))
    window.location.reload()
  }

  const getLocation = (): any => {
    const locationFromCookie = localStorage.getItem(GeoLocationCookieType.GEOLOCATION)
    if (locationFromCookie) {
      return JSON.parse(locationFromCookie)
    }
  }
  return { getLocation }
}
export default useGeolocation
